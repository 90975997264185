<template>
  <div>
    <v-row>
      <v-col cols="12">
        <branch-scheduled />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { GetById } from '@core/api/Branch'
import { onMounted, ref } from '@vue/composition-api'
import BranchScheduled from './Components/BranchScheduled.vue'
// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { BranchScheduled },
  setup() {
    const myBranch = ref({})
    const GetBransh = () => {
      GetById({ Id: JSON.parse(localStorage.getItem('userData')).BranshId })
        .then(response => {
          // handle success
          myBranch.value = response
          // remove loading state
        })
        .catch(error => {
          console.log(error)
        })
    }
    onMounted(() => {
      GetBransh()
    })
    return { myBranch, GetBransh }
  },
}
</script>
