<template>
  <div  class="d-flex flex-column ">
      <!-- Main Card -->
      <app-card-actions @refresh="fetchScheduleOrders()">
        <template slot="title"> Orders List </template>
        <!-- search -->
        <v-card-text class="d-flex justify-center align-center pb-0">

          <div class="d-flex align-center justify-center pb-2" >
            <span class="d-flex mr-5 mt-2">
             <v-datetime-picker label="Date From" v-model="searchDateFrom">
              <template slot="dateIcon">
                <v-icon>{{ icons.mdiCalendarRange }}</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>{{ icons.mdiClockOutline }}</v-icon>
              </template></v-datetime-picker
            >
            </span>
            <span class="d-flex  ml-5 mr-5 ">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search "
              clearable
            ></v-text-field>
            </span>
            <span class="d-flex  ml-5 mr-5 ">
            <v-select
              v-model="statusFilter"
              label="Status"
              :items="$store.state.status['orders']"
              item-text="title"
              item-value="finished"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
            </span>
          </div>
        </v-card-text>

        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="OrderListTablef"
          item-key="Id"
          :sort-by="['ScheduleTimeOrder']"
           :sort-desc="[false]"
          :items-per-page="10"
          :options.sync="options"
          :expanded.sync="expanded"
          show-expand
          :loading="loading"

        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>

          <!-- Name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
          <!-- CreationTime -->
          <template #[`item.ScheduleTimeOrder`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{
                  filter.parseTime(item.ScheduleTimeOrder, '{y}-{m}-{d} {h}:{i}')
                }}</span>
              </div>
            </div>
          </template>
        <template #[`item.TotalAmount`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.TotalAmount.toFixed(3) }} JD</span>
              </div>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).title
              }}
            </v-chip>
          </template>
          <!-- Action -->
          <template #[`item.Action`]="{ item }">
            <vc-change-status
              v-if="item.Status == 'pinding'"
              @refetch-data="fetchScheduleOrders"
              :ObjectId="item.Id"
              TableName="orders"
              Status="accept"
              :WithDialog="false"
            />
            <vc-change-status
              v-if="item.Status == 'pinding'"
              @refetch-data="fetchScheduleOrders"
              :ObjectId="item.Id"
              TableName="orders"
              :WithDialog="true"
              Status="cancelled"
            />
            <v-row v-if="item.Status == 'accept'" class="mt-2 mb-2">
              <v-col cols="7">
             <vc-change-status
              @refetch-data="fetchScheduleOrders"
              :ObjectId="item.Id"
              :WithDialog="false"
              TableName="orders"
              Status="finished"
            />
              </v-col>
              <v-col cols="5">
                 <drawer-print Type="Order" :Data="item" />
              </v-col>
            </v-row>

            <v-row v-if="item.Status == 'finished'" class="mt-2 mb-2">
               <v-col cols="7"></v-col>
                <v-col cols="5"><drawer-print Type="Order" :Data="item" /></v-col>

            </v-row>
          </template>
          <!-- expandad data -->
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length / 2">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="6" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Order Items</h3></span>
                    </v-col>
                    <v-col lg="6" md="8" sm="8" cols="8"> </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>

                <v-row v-for="(orderTransation, i) in item.OrderTransations" :key="i">
                  <v-col lg="7" cols="7"
                    >{{ orderTransation.Name }}
                    <br />
                    <span v-for="(Addon, i) in orderTransation.Addon2" :key="i"
                      >Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                    /></span>
                    <span v-if="orderTransation.Description != ''"
                      >Note : {{ orderTransation.Description }} <br
                    /></span>
                  </v-col>
                  <v-col lg="2" cols="2">
                    {{ orderTransation.Qty }}
                  </v-col>
                  <v-col>
                    {{ (parseFloat(orderTransation.TotalPrice) * orderTransation.Qty).toFixed(2) }}
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-1"></v-divider>

                <v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0).toFixed(2)
                      }}
                      JOD
                    </span>
                  </v-col></v-row
                >
                     <div v-if="item.OrderPromos !=''">
                <v-divider class="mt-1 mb-1"></v-divider>
                 <v-row v-if="item.PromoCodePercentage >0" >
                  <v-col lg="7" cols="7">
                    <p class="mb-1">PromoCodePercentage:</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p class="mb-1">{{ (item.PromoCodePercentage)*100 }}%</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p class="mb-1">{{
                    (item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        * item.PromoCodePercentage
                        ).toFixed(3) }}
                        </p>
                  </v-col>
                </v-row>
                <v-row v-if="item.PromoCodeAmount != 0">
                  <v-col lg="9" cols="9">
                    <p  class="mb-1">PromoCodeAmount:</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p  class="mb-1">{{ (item.PromoCodeAmount).toFixed(3) }}</p>
                  </v-col>
                </v-row>
<v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Total</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        (((item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        )+item.PromoCodeAmount)-
                        (item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        * item.PromoCodePercentage
                        )).toFixed(3)
                      }}
                      JOD
                    </span>

                  </v-col></v-row
                >
                </div>
                <v-divider class="mt-1 mb-1"></v-divider>
                <p v-if="item.Description != null" class="mb-1">Description: {{ item.Description }}</p>
                <p class="mb-1">DeliveryPrice: {{ item.DeliveryPrice.toFixed(3) }} JD</p>
                <p class="mb-1">TotalAmount: {{ item.TotalAmount }}</p>
              </v-card>
            </td>
             <td :colspan="headers.length / 2">
              <v-row>
                     <v-col lg="10" md="4" sm="4" cols="10">
                     <span class="d-flex justify-end align-center  mb-6">
                      <print-component v-if="item.Status == 'accept' || item.Status == 'finished'" Type="Order" :Data="item" />
                      </span>
                    </v-col>
                  </v-row>
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="12" md="4" sm="4" cols="12">
                      <span class="d-flex justify-start"><h3>Customer & Address</h3></span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>
                <p class="my-1">Name: {{ item.Name }}</p>
                <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
                <p class="mb-1">Order Type: {{ item.OrderType }}</p>
                <p v-if="item.CreationTime > '2020-01-01 02:00:00.0000000'" class="mb-1">
                  Order Creation Time: {{ filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}') }}
                </p>
                <p class="mb-1">Delivery Type: {{ item.DeliveryType }}</p>
                <p v-if="item.DeliveryCompany != null" class="mb-1">Delivery Company: {{ item.DeliveryCompany }}</p>
                <p class="mb-1">Order Source: {{ item.OrderSource }}</p>
                <p v-if="item.OrderSourceRefNumber != null" class="mb-1">
                  Order Source Ref Number: {{ item.OrderSourceRefNumber }}
                </p>
                <p v-if="item.City != null" class="mb-1">City: {{ item.City }}</p>
                <p v-if="item.BranshAddress != null" class="mb-1">BranshAddress: {{ item.BranshAddress }}</p>
                <p v-if="item.AddressDetail != null" class="mb-1">AddressDetail: {{ item.AddressDetail }}</p>
                <p v-if="item.BuildingNo != null" class="mb-1">BuildingNo: {{ item.BuildingNo }}</p>
                <p v-if="item.StreetNo != null" class="mb-1">StreetNo: {{ item.StreetNo }}</p>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </app-card-actions>

    </div>
</template>

<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import DrawerPrint from '@/components/vc-print/DrawerPrint.vue'
import PrintComponent from '@/components/vc-print/PrintComponent.vue'
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import { parseTime } from '@core/utils/filter'
import { mdiCalendarRange, mdiClockOutline } from '@mdi/js'
import useScheduleList from './useScheduleList'
export default {
  components: { VcChangeStatus, AppCardActions, DrawerPrint, PrintComponent },
  setup() {

    const {
      fetchScheduleOrders,
      OrderListTablef,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalOrderListTablef,
      loading,
      expanded,
      searchDateFrom,
      OrderTotalLocal,
    } = useScheduleList()
    return {
      searchDateFrom,
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalOrderListTablef,
      OrderListTablef,
      loading,
      expanded,
      fetchScheduleOrders,
      OrderTotalLocal,
      filter: { parseTime },
      icons: {
        mdiClockOutline,
        mdiCalendarRange,
      },
    }
  },
}
</script>
