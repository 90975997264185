import { GetSchedule } from '@core/api/Order'
import { DateTimeFormatter, Instant, LocalDateTime } from '@js-joda/core'
import { ref, watch } from '@vue/composition-api'

export default function useScheduleList() {
  // Table Handlers
  const tableColumns = [
    { text: 'id', value: 'Id' },
    { text: 'Customer Name', value: 'Name' },
    { text: 'Phone', value: 'PhoneNumber'},
    { text: 'Schedule Time', value: 'ScheduleTimeOrder' },
    { text: 'Amount', value: 'TotalAmount' },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Action', value: 'Action', sortable: false },
  ]
  const OrderListTable = ref([])
  const OrderListTablef = ref([])
  const expanded = ref([])
  const searchQuery = ref('')
  const searchDateFrom = ref(new Date())
  const searchDateTo = ref(new Date())
  const statusFilter = ref(null)
  const totalOrderListTable = ref(0)
  const totalOrderListTablef = ref(0)
  const OrderTotalLocal = ref([])
  searchDateFrom.value.setHours(0, 0, 0, 0)
  searchDateTo.value.setHours(23, 59, 59, 999)
  searchDateTo.value = searchDateFrom.value;
  const options = ref({
    sortBy: ['id'],
    sortDesc: [false],
  })
  const loading = ref(false)
  const fetchScheduleOrders = () => {
    let day = searchDateFrom.value.getDate()
     searchDateTo.value.setDate(day)
    searchDateTo.value.setHours(23, 59, 59, 999)
    console.log('Date From',searchDateFrom.value)
    console.log('Date To',searchDateTo.value)
    const BranshId = JSON.parse(localStorage.getItem('userData')).BranshId
    GetSchedule({
      BranchId: BranshId,
      Any: searchQuery.value,
      Status: statusFilter.value,
      DateTo: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateTo.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),),
        DateFrom: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateFrom.value)).format(
          DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),),
    })
      .then(response => {
        response.Items.forEach((i, index) => {

          i.OrderTransations.forEach((item, index) => {

             if(item.Addon != ''){
              item.Addon = JSON.parse(item.Addon)
              item.Addon2 = item.Addon
             }
            })
        })
        // handle success
        OrderListTablef.value = response.Items
        totalOrderListTablef.value = response.length
        OrderTotalLocal.value = response.Totals
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        loading.value = false

        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options, searchDateFrom], () => {
    loading.value = true
    fetchScheduleOrders()
  })
  return {
    fetchScheduleOrders,
    expanded,
    tableColumns,
    searchQuery,
    options,
    OrderListTable,
    OrderListTablef,
    statusFilter,
    totalOrderListTable,
    totalOrderListTablef,
    loading,
    searchDateTo,
    searchDateFrom,
    OrderTotalLocal,
  }
}
